import { render, staticRenderFns } from "./templateMail.vue?vue&type=template&id=ffa68340&scoped=true"
import script from "./templateMail.vue?vue&type=script&lang=js"
export * from "./templateMail.vue?vue&type=script&lang=js"
import style0 from "./templateMail.vue?vue&type=style&index=0&id=ffa68340&prod&scoped=true&lang=scss"
import style1 from "./templateMail.vue?vue&type=style&index=1&id=ffa68340&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffa68340",
  null
  
)

export default component.exports