<template>
  <div class="template-mail-cdg">
    <v-row class="box-mail-frais mb-1">
      <v-col cols="2">
        <div class="sub-box">
          <div class="title-mail-cdg">Commerciaux</div>
          <v-checkbox
            class="label-checked-global"
            v-model="checkAllFiliale"
            @change="checkAllFilialeMethod"
            color="#704ad1"
            label="Tous"
            hide-details
            :retain-focus="false"
            :eager="true"
          ></v-checkbox>
          <div class="content-checked">
            <div
              class="d-flex content-checked-items "
              v-for="(commercial, indexClient) in dataToUse.simulations"
              :key="'client' + indexClient"
            >
              <v-checkbox
                @change="checkFiliale(commercial)"
                v-model="commercial.check"
                color="#704ad1"
                :label="commercial.vendeur"
                hide-details
                :class="{
                  'check-title-mail-cdg-active': indexClient == indexM
                }"
              >
                <template v-slot:label>
                  <div @click.prevent.stop="indexM = indexClient">
                    {{ commercial.vendeur }}
                  </div>
                </template>
              </v-checkbox>
              <div>
                <font-awesome-icon
                  v-if="indexClient == indexM"
                  class="icon-selected-filiale mr-2"
                  icon="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="action-client">
            <v-btn
              class="color-btn-email"
              small
              text
              @click.prevent.stop="sendMail"
              :disabled="computedDisabledSend || loaderSendMail"
              :loading="loaderSendMail"
            >
              <span>
                Envoyer
                <div class="loading ml-2"></div>
              </span>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="10">
        <div class="sub-box">
          <div class="editor-vendeur-content">
            <step-content :dataToUse="dataToUse.simulations[indexM]" />
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="actionModelMailCdg d-flex justify-content-center">
      <v-btn
        color="#704ad1"
        text
        small
        class="btn-circle mr-2"
        v-if="indexM < dataToUse.simulations.length && indexM > 0"
        @click="previousMail()"
      >
        <span>
          Précédent
          <div class="loading ml-2"></div>
        </span>
      </v-btn>
      <span
        v-for="(i, indexlot) in dataToUse.simulations"
        :key="'lotIndex' + indexlot"
        class="circleStep"
        :class="{ circleStepActive: indexlot == indexM }"
      ></span>
      <v-btn
        text
        small
        color="#704ad1"
        class="btn-circle ml-2"
        v-if="indexM < dataToUse.simulations.length - 1"
        @click="nextMail()"
      >
        <span>
          Suivant
          <div class="loading ml-2"></div>
        </span>
      </v-btn>
    </div>
    <custom-swal
      :dataToShow="resultMail"
      v-if="showResultSwal"
      @closeResultSwal="showResultSwal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    changeVendeurIndex: { required: true }
  },
  data() {
    return {
      indexM: 0,
      checkAllFiliale: true,
      resultMail: null,
      showResultSwal: false,
      loaderSendMail: false
    };
  },
  computed: {
    ...mapGetters(['getLoaderSendMailCdg']),
    computedDisabledSend() {
      return (
        this.loaderSendMail ||
        (this.dataToUse != null &&
          this.dataToUse.simulations.filter(i => i.check == true).length == 0)
      );
    }
  },
  methods: {
    ...mapActions(['sendMailAppelCommercial']),
    previousMail() {
      this.indexM = this.indexM - 1;
    },
    nextMail() {
      this.indexM = this.indexM + 1;
    },
    confirmFilialeMail() {
      this.dataToUse.simulations[this.indexM].check = true;
      this.verifCheckAll();
      if (this.indexM < this.dataToUse.simulations.length - 1) {
        this.indexM = this.indexM + 1;
      }
    },
    async sendMail() {
      this.loaderSendMail = true;
      if (
        this.dataToUse &&
        this.dataToUse.simulations &&
        this.dataToUse.simulations.filter(item => item.check == true).length > 0
      ) {
        let payload = new FormData();
        this.dataToUse.simulations.forEach((element, i) => {
          if (element.check == true) {
            payload.append(
              'mail_to_send[' + i + '][content_pdf]',
              element.content_pdf
            );
            payload.append(
              'mail_to_send[' + i + '][content_mail]',
              element.content_mail
            );
            payload.append(
              'mail_to_send[' + i + '][send_to_client]',
              element.send_to_client
            );
            //client property
            payload.append(
              'mail_to_send[' + i + '][client][id]',
              element.client_id
            );
            payload.append(
              'mail_to_send[' + i + '][client][email]',
              element.client_mail
            );
            payload.append(
              'mail_to_send[' + i + '][client][name]',
              element.client
            );
            //end proprty client
            //vendeur property
            payload.append(
              'mail_to_send[' + i + '][send_to_vendeur]',
              element.send_to_vendeur
            );
            payload.append(
              'mail_to_send[' + i + '][vendeur][id]',
              element.vendeur_id
            );
            payload.append(
              'mail_to_send[' + i + '][vendeur][email]',
              element.vendeur_mail
            );
            payload.append(
              'mail_to_send[' + i + '][vendeur][name]',
              element.vendeur
            );
            element.dossiers.forEach((id, index) => {
              payload.append(
                'mail_to_send[' + i + '][dossiers][' + index + ']',
                id
              );
            });
          }
        });
        const response = await this.sendMailAppelCommercial(payload);
        if (response.succes) {
          this.$emit('eventMailSend');
          this.resultMail = response.data;
          this.showResultSwal = true;
          this.loaderSendMail = false;
        } else {
          this.loaderSendMail = false;
        }
      }
    },
    checkAllFilialeMethod() {
      this.dataToUse.simulations.map(i => (i.check = this.checkAllFiliale));
    },
    checkFiliale(client) {
      client.check = !client.check;
      this.verifCheckAll();
    },
    verifCheckAll() {
      if (
        this.dataToUse.simulations.filter(i => i.check == true).length ==
        this.dataToUse.simulations.length
      ) {
        this.checkAllFiliale = true;
      } else {
        this.checkAllFiliale = false;
      }
    }
  },
  components: {
    stepContent: () => import('./stepContent.vue'),
    customSwal: () => import('./customSwal.vue')
  },
  watch: {
    changeVendeurIndex: function() {
      this.indexM = 0;
      this.checkAllFiliale = true;
    }
  }
};
</script>

<style scoped lang="scss">
.template-mail-cdg {
  height: calc(100vh - 120px);
  .box-mail-frais {
    height: calc(100vh - 105px);
    .sub-box {
      height: calc(100vh - 122px);
      overflow-x: hidden;
      background-color: #f6f5fb;
      border: 1px solid #e0ddf0;
      margin-bottom: 8px;
      border-radius: 10px;
      padding: 6px;
      .content-client {
        height: calc(100vh - 210px);
      }
      .action-client {
        text-align-last: center;
        height: 26px;
        .lot-title {
          position: absolute;
          right: 37px;
          font-weight: 700;
          margin-top: 8px;
          font-size: 20px;
          color: #704ad1;
        }
      }
      .check-title-mail-cdg {
        font-size: 10px;
        font-weight: 600;
        cursor: pointer;
      }
      .check-title-mail-cdg-active {
        font-size: 13px;
        font-weight: 800;
      }
      .check-icon-mail-cdg {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        margin-right: 10px;
        background-color: #fff;
      }
      .checked-icon-mail-cdg {
        background: #7b58d5;
        color: #fff;
        text-align: center;
        border-color: #704ad1;
        font-size: 9px;
      }
      .icon-selected-filiale {
        color: #7b58d5;
      }
    }
    .sub-box::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    .sub-box::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 10px;
    }
    .sub-box::-webkit-scrollbar-thumb {
      background: #704ad1;
      border-radius: 7px;
    }
  }
  .editor-vendeur-content {
    height: calc(100vh - 120px);
  }
  .actionModelMailCdg {
    // padding-bottom: 18px;
    align-items: center;
    justify-content: center;
    // padding-top: 18px;
    .btn-circle {
      height: 28px;
      min-width: 64px;
      padding: 5px 8px;
      margin-bottom: -6px;
    }
  }
  .content-checked-items {
    align-items: flex-end;
    justify-content: space-between;
  }
  .title-mail-cdg {
    color: #7b58d5;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: -9px;
  }
  .color-btn-email {
    background: rgb(112, 74, 209);
    color: #ffffff !important;
    caret-color: rgb(112, 74, 209);
  }
  .content-checked {
    height: calc(100vh - 241px);
    overflow: auto;
    margin-top: 4px;
    margin-bottom: 15px;
  }
}
</style>
<style lang="scss">
.input-mail-commercial.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined {
  padding-top: 14px;
}
.content-checked {
  .v-input .v-label {
    font-size: 10px !important;
    font-family: 'Montserrat', sans-serif;
  }
  .v-input--selection-controls {
    margin-top: 0px;
    padding: 0px;
  }
}
.label-checked-global {
  .v-label {
    font-size: 10px !important;
    font-family: 'Montserrat', sans-serif;
  }
}
</style>
